import React from 'react';
import Layout from '../components/layout';

import SearchView from '../views/searchView'

const SearchPage = () => (
	<Layout isBlack>
		<SearchView />
	</Layout>
)

export default SearchPage;