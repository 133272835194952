import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import MuiTextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { graphql, Link, useStaticQuery } from "gatsby"

import Title from "../../components/title"

const TextField = withStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.common.black,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.common.black,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.black,
      },
    },
  },
}))(MuiTextField);

const useStyles = makeStyles(theme => ({
  grid: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
  },
  search: {
  },
  link: {
    textDecoration: 'none',
    display: "inline",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:hover:active": {
      textDecoration: "underline",
    },
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2)
    }
  }
}))

const SearchPage = ({ data }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState('')
  const [filteredSaddles, setFilteredSaddles] = React.useState([])

	const { allDatoCmsPageSaddle = {} } = useStaticQuery(graphql`
		query querySearch {
      allDatoCmsPageSaddle(sort: {fields: position, order: ASC}) {
        nodes {
          id
          slug
          saddleName
          saddleImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
		}
	`)

  const saddles = allDatoCmsPageSaddle && allDatoCmsPageSaddle.nodes || []

  const handleChange = ({ target }) => {
    setValue(target.value)
    if (!target.value) {
      setFilteredSaddles([])
      return
    }
    const filteredSaddles = saddles.filter(saddle => {
      const saddleLowerName = saddle.saddleName.toLowerCase()
      return saddleLowerName.includes(value.toLowerCase())
    })
    setFilteredSaddles(filteredSaddles)
  }


  return (
    <Container maxWidth="md">
      <Box mt={15} mb={4} display="flex" justifyContent="center">
        <Title>Wyszukaj</Title>
      </Box>
      <TextField
        value={value}
        color="primary"
        variant="standard"
        onChange={handleChange}
        fullWidth
        autoFocus
        hiddenLabel
      />
      <Box mt={8} mb={10} className={classes.search}>
        {Array.isArray(filteredSaddles) && filteredSaddles.length 
          ? filteredSaddles.map(({ id, saddleName, saddleImage }) => (
            <Link to="/" key={id} className={classes.link}>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item md={1} xs={12}>
                  <GatsbyImage image={getImage(saddleImage)} alt={saddleName} />
                </Grid>
                <Grid item md={11} xs={12}>
                  <Typography variant="h5">{saddleName}</Typography>
                </Grid>
              </Grid>
            </Link>
          )) : (
            <Box display="flex" justifyContent="center">
              <Typography variant="h5">Brak wyników... :(</Typography>
            </Box>
          )}
      </Box>
    </Container>
  )
}

export default SearchPage
